import {Box, Grid, Typography} from "@mui/material";
import React from "react";
import heroHome from "../assets/imgs/anais.jpg";
import Page from "./Page";

const AboutPage = () => {

    return<Page title="O nama">
        <Grid container spacing={12}>
            <Grid item xs={12} lg={6}>
                <Typography variant={"h2"} sx={{ fontWeight: "normal", my: 8 }}>
                    O nama
                </Typography>
                <Typography sx={{ mb: 10 }} fontWeight={"normal"}>
                    Brend „ Italiana Salotti“, proizvođača „Diva Divani“, prisutan je na tržištu Srbije od 2003. godine.
                    Sedištem u Italiji (Vicenza) i proizvodnjom u Srbiji (Vranjska banja), kompanija nudi tapacirani nameštaj
                    visokog kvaliteta i modernog dizajna u prvoklasnoj koži i mebl štofu.
                    TP „TRGOSISTEM“ d.o.o iz Zemuna je zastupnik brenda nameštaja „Italiana Salotti“ i posluje u Novom
                    Beogradu. Prodajni salon se nalazi u ulici Jurija Gagarina 22e, lokal 2, A Blok. Ponuđeni modeli mahom su
                    modularni tako da je izbor elemenata i kombinacija velik i zadovoljava sve potrebe.
                </Typography>
            </Grid>
            <Grid item xs={12} lg={6}>
                <Box my={8} width="100%">
                    <img
                        src={heroHome}
                        style={{
                            objectFit: "cover",
                            maxWidth: "100%",
                        }}
                        alt={"slide.alt"}
                    />
                </Box>
            </Grid>
        </Grid>
    </Page>

};

export default AboutPage;
