import { Box, IconButton, Typography } from "@mui/material";
import caprice from "../../assets/caprice.jpeg";
import React from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
const ProductDashboardItem = () => (
  <Box
    display="flex"
    flexDirection="row"
    p={1}
    style={{ gap: "16px", border: "1px solid black", borderRadius: "20px" }}
  >
    <img src={caprice} height="150px" style={{ objectFit: "contain" }} />
    <Box flex={1}>
      <Typography>Caprice</Typography>
    </Box>
    <Box justifySelf="flex-end">
      <IconButton>
        <EditIcon />
      </IconButton>
      <IconButton>
        <DeleteIcon />
      </IconButton>
      <IconButton>
        <ContentCopyIcon />
      </IconButton>
    </Box>
  </Box>
);

export default ProductDashboardItem;
