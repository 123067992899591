import { createTheme } from "@mui/material/styles";

export const themeLight = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 776,
      md: 900,
      lg: 1200,
      xl: 1536,
    }
  },
  palette: {
    text: {
      primary: "#101010",
    },
    background: {
      default: "#ECE9E3",
    },
  },
  typography: {
    fontFamily: [
      "MADE Mirage",
      "Satoshi",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    h1: {
      fontWeight: 500,
    },
    h2: {
      fontWeight: 600,
    },
    h3: {
      fontWeight: 400,
      fontSize: "2.5rem",
      textTransform: "uppercase",
    },
    h4: {
      fontWeight: 400,
      fontSize: "2rem",
    },
    h5: {
      fontWeight: 500,
    },
    body1: {
      fontSize: "1.3rem",
      fontWeight: 500,
      lineHeight: 1.4,
    },
    body2: {
      fontSize: "1.15rem",
      fontWeight: 500,
    },
  },
  components: {
    MuiLink: {
      defaultProps: {
        underline: "hover",
        color: "inherit",
      },
    },
  },
});
