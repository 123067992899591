import ProductDashboardItem from "../../components/admin/ProductDashboardItem";
import {
    Box,
    Button,
    Container,
    Drawer,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import useModal from "../../hooks/useModal";
import {Controller, FormProvider, useForm} from "react-hook-form";
import {makeStyles} from "@mui/styles";
import {FurnitureType, getFurnitureDefaultItem, SofaFurniture,} from "../../Furniture";
import {useCallback} from "react";
import {useDropzone} from "react-dropzone";
import RHFTextInput from "../../components/admin/inputs/RHFTextInput";

const useStyles = makeStyles(() => ({
    root: {
        "& .MuiFormHelperText-root": {
            color: "#d32f2f",
        },
    },
}));

interface FurnitureLight {
    name: string;
    description: string;
}

const ProductDashboardPage = () => {
    const classes = useStyles();
    const modal = useModal();
    const methods = useForm<SofaFurniture>({
        defaultValues: getFurnitureDefaultItem(FurnitureType.SOFA) as SofaFurniture,
    });

    const onAddFurniture = (data: SofaFurniture) => {
        console.log(data);
        // processFile(data, addProduct, path)
    };

    const onDrop = useCallback((acceptedFiles: File[]) => {
        // Do something with the files
        methods.setValue("images", acceptedFiles);
    }, [methods]);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop,
        noClick: true,
    });

    return (
        <Container maxWidth="xl">

            <Button onClick={modal.openModal}>Dodaj model</Button>
            <Container>
                <ProductDashboardItem/>
            </Container>
            <Drawer anchor="right" open={modal.isOpen} onClose={modal.closeModal}>
                <Box m={10} flex={1} width="500px">
                    <Typography variant="h5" gutterBottom>
                        Dodavanje novog modela
                    </Typography>

                    <Box
                        style={{height: "100%"}}
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-between"
                    >
                        <FormProvider {...methods}>
                            <form
                                id="user-form"
                                onSubmit={methods.handleSubmit(onAddFurniture)}
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "16px",
                                }}
                            >

                                <RHFTextInput name="name"/>
                                <RHFTextInput name="description"/>
                                {/*<FormControl fullWidth>*/}
                                {/*    <InputLabel id="input-result-label">Tip</InputLabel>*/}
                                {/*    <Controller*/}
                                {/*        name="type"*/}
                                {/*        control={control}*/}
                                {/*        rules={{*/}
                                {/*            required: {*/}
                                {/*                value: true,*/}
                                {/*                message: "Ovo polje je obavezno.",*/}
                                {/*            },*/}
                                {/*        }}*/}
                                {/*        render={({field}) => (*/}
                                {/*            <Select {...field} label="Tip" style={{marginBottom: 15}}>*/}
                                {/*                {Object.keys(FurnitureType).map((res) => (*/}
                                {/*                    <MenuItem value={res} key={res}>*/}
                                {/*                        {res}*/}
                                {/*                    </MenuItem>*/}
                                {/*                ))}*/}
                                {/*            </Select>*/}
                                {/*        )}*/}
                                {/*    />*/}
                                {/*</FormControl>*/}
                                {/*<div*/}
                                {/*    {...getRootProps()}*/}
                                {/*    style={{*/}
                                {/*        border: isDragActive ? "2px dashed blue" : "2px dashed black",*/}
                                {/*        padding: "16px",*/}
                                {/*    }}*/}
                                {/*>*/}
                                {/*    <input {...getInputProps()} />*/}
                                {/*    {isDragActive ? (*/}
                                {/*        <p>Drop the files here ...</p>*/}
                                {/*    ) : (*/}
                                {/*        <p>Drag 'n' drop some files here, or click to select files</p>*/}
                                {/*    )}*/}
                                {/*</div>*/}
                                {/*<Box*/}
                                {/*    display="flex"*/}
                                {/*    style={{gap: "8px"}}*/}
                                {/*    // flex={1}*/}
                                {/*    overflow="scroll"*/}
                                {/*>*/}
                                {/*    {[].map((file) => (*/}
                                {/*        <Box display="flex" flexDirection="column" color="success">*/}
                                {/*            <img*/}
                                {/*                src={URL.createObjectURL(file)}*/}
                                {/*                style={{maxHeight: "100px"}}*/}
                                {/*            />*/}
                                {/*            <Button>Obrisi</Button>*/}
                                {/*        </Box>*/}
                                {/*    ))}*/}
                                {/*</Box>*/}
                            </form>
                            <Button
                                type="submit"
                                color="success"
                                variant="contained"
                                form="user-form"
                            >
                                Dodaj model
                            </Button>
                        </FormProvider>

                    </Box>
                </Box>
            </Drawer>
        </Container>
    );
};

export default ProductDashboardPage;
