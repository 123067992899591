import React, { useState } from "react";
import {
    Box,
    DialogContent, DialogTitle,
    Grid,
    IconButton,
    Stack,
    Typography,
} from "@mui/material";
import stolovi from "../assets/stolovi.jpeg";
import kreveti from "../assets/kreveti.jpeg";
import garniture from "../assets/garniture.jpeg";
import sofeSaMehanizmom from "../assets/sofa-sa-mehanizmom.jpeg";
import sofeBezMehanizma from "../assets/sofe-bez-mehanizma.jpg";
import fotelje from "../assets/fotelje.jpeg";
import CategoryCard from "../components/CategoryCard";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/free-mode";
import heroHome from "../assets/imgs/anais.jpg";
import SwiperCore, { Autoplay } from "swiper";
import Page from "./Page";
import { useFirestoreCollection } from "../hooks/firestore/useFirestoreCollection"; // Import Swiper core and Autoplay module
import { where } from "firebase/firestore";
import Product from "../model/Product";
import CloseIcon from "@mui/icons-material/Close";
import { BootstrapDialog } from "../components/PhotoViewer";
import promotion from "../assets/promotion.jpg";
import promotionMobile from "../assets/promotion_mobile.jpg";
import {useNavigate} from "react-router-dom";
// Install Autoplay module
SwiperCore.use([Autoplay]);

const categories = [
  {
    title: "Garniture",
    description: "Istražite brojne sofe i fotelje",
    image: garniture,
    href: "/proizvodi",
  },
  {
    title: "Kreveti",
    description: "Najbolje za vaš san",
    image: kreveti,
    href: "https://www.bsideletti.it/",
  },
  {
    title: "Stolovi",
    description: "Iskoristite svaki trenutak sa bližnjima",
    image: stolovi,
    href: "https://www.cattelanitalia.com/",
  },
];

export const sofaTypes = [
    {
        title: "Sofe bez mehanizma",
        image: sofeBezMehanizma,
        href: "/product",
    },
    {
    title: "Sofe sa mehanizmom",
    image: sofeSaMehanizmom,
    href: "/product",
  },
  {
    title: "Fotelje i taburei",
    image: fotelje,
    href: "/product",
  },
];

const HomePage = () => {
  const featuredProducts = useFirestoreCollection<Product>("products", {
    queryConstraints: [where("featured", "==", true)],
  });
  const [isOpen, setOpen] = useState(true);

  return (
    <Page title="Italijanski namestaj">
      <Stack spacing={10} my={5}>
        <Box
          display="flex"
          sx={{
            flexDirection: {
              xs: "column",
              md: "row",
            },
            justifyContent: "space-between",
            gap: "40px",
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", md: "65%" },
              borderTopLeftRadius: "280px",
              overflow: "hidden",
            }}
          >
            <img
              src={heroHome}
              style={{
                objectFit: "cover",
                maxWidth: "100%",
              }}
              alt={"slide.alt"}
            />
          </Box>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography
              variant="h4"
              textTransform="uppercase"
              sx={{ textAlign: { xs: "center", md: "left" } }}
            >
              ulepšajte svoje okruženje uz luksuzan i komforan nameštaj
            </Typography>
          </Box>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Typography
            style={{
              borderBottom: "0.6px solid black",
              borderTop: "0.6px solid black",
            }}
            px={3}
            py={1}
            display="block"
            margin="0 auto"
            textAlign="center"
            gutterBottom
          >
            Osvajamo poverenje klijenata <br /> od 2003. godine
          </Typography>
        </Box>
        <Box
          style={{ backgroundColor: "#E0DCD2" }}
          sx={{ p: 2 }}
          display="flex"
          flexDirection="column"
        >
          <Typography
            style={{
              borderBottom: "0.6px solid black",
            }}
            px={3}
            pb={1}
            display="block"
            margin="0 auto"
            textAlign="center"
            gutterBottom
            variant="h3"
          >
            NaŠ asortiman
          </Typography>
          <Grid container spacing={4} sx={{ my: 4 }}>
            {categories.map((category, index) => (
              <Grid key={index} item lg={4} md={6} xs={12}>
                <CategoryCard
                  title={category.title}
                  img={category.image}
                  href={category.href}
                  description={category.description}
                />
              </Grid>
            ))}
          </Grid>
        </Box>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Typography
            style={{
              borderBottom: "0.6px solid black",
            }}
            px={3}
            pb={1}
            display="block"
            margin="0 auto"
            textAlign="center"
            gutterBottom
            variant="h3"
          >
            istaknuti proizvodi
          </Typography>
        </Box>
        <Box style={{ backgroundColor: "#D3CFC6" }} px={4} py={4}>
          <Swiper
            slidesPerView={"auto"}
            spaceBetween={60}
            initialSlide={3}
            autoplay={{ delay: 2000 }}
          >
            {featuredProducts.data?.map((c, index) => (
              <SwiperSlide style={{ maxWidth: "min-content" }} key={index}>
                <CategoryCard
                  title={c.name}
                  img={c.images[0]}
                  href={`proizvodi/${c.id}`}
                  fixedSize
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>

        <Box
          display="flex"
          sx={{
            flexDirection: {
              xs: "column",
              md: "row",
            },
            backgroundColor: "#56645F",
            borderBottomRightRadius: "280px",
            p: {
              xs: 6,
              md: 10,
            },
            gap: "48px",
          }}
        >
          <Box>
            <Typography
              variant="h3"
              color={"white"}
              sx={{
                textAlign: { xs: "center", md: "left" },
                marginBottom: "30px",
              }}
            >
              Tržište i inovacije zahtevaju specijalizaciju
            </Typography>
            <Typography
              color={"white"}
              sx={{ textAlign: { xs: "center", md: "left" } }}
            >
              Naš cilj je da budemo u mogućnosti da svakodnevno shvatimo izazove
              tržišta, inovacije i trendove potražnje stavljajući se uz bok
              našim kupcima predlažući rešenja i zajedno sa njima dizajnirajući
              najatraktivnije kolekcije i modele za korisnika u smislu
              udobnosti, elegancije , presvlake i održivost.
            </Typography>
          </Box>
          <Box
            p={3}
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              borderLeft: { xs: "none", md: "0.6px solid #FFFFFF" },
              borderTop: { xs: "0.6px solid #FFFFFF", md: "none" },
            }}
          >
            <svg
              style={{ width: "200px", height: "200px" }}
              width="334"
              height="334"
              viewBox="0 0 334 334"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M102.252 184.786C95.007 182.112 88.5964 177.009 84.4402 169.81C75.0027 153.464 80.622 132.322 97.0223 122.853L124.086 107.228C140.432 97.7905 161.605 103.464 171.043 119.81C180.48 136.156 174.861 157.299 158.461 166.767L144.929 174.58"
                stroke="white"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M196.757 130.223C204.002 132.896 210.412 138 214.568 145.199C224.006 161.545 218.387 182.687 201.986 192.156L174.923 207.781C158.577 217.218 137.403 211.545 127.966 195.199C118.528 178.852 124.148 157.71 140.548 148.241L154.08 140.429"
                stroke="white"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Box>
        </Box>

        <Box
          display="flex"
          sx={{
            flexDirection: {
              xs: "column",
              md: "row",
            },
            backgroundColor: "white",
            borderBottomLeftRadius: { md: "280px" },
            p: {
              xs: 6,
              md: 12,
            },
            gap: "48px",
          }}
        >
          <Box sx={{ maxWidth: { md: "60%" } }}>
            <Typography variant="h3" gutterBottom>
              O nama
            </Typography>
            <Typography>
              Naš cilj je da budemo u mogućnosti da svakodnevno shvatimo izazove
              tržišta, inovacije i trendove potražnje stavljajući se uz bok
              našim kupcima predlažući rešenja i zajedno sa njima dizajnirajući
              najatraktivnije kolekcije i modele za korisnika u smislu
              udobnosti, elegancije , presvlake i održivost.
            </Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              borderLeft: { xs: "none", md: "0.6px solid #737373" },
              borderTop: { xs: "0.6px solid #737373", md: "none" },
              paddingLeft: { md: "48px" },
              paddingTop: { xs: "48px", md: "0px" },
            }}
          >
            <Box
              sx={{
                overflow: "hidden",
                borderTopRightRadius: { md: "120px" },
              }}
            >
              <img
                src={heroHome}
                style={{
                  objectFit: "cover",
                  maxWidth: "100%",
                }}
                alt={"slide.alt"}
              />
            </Box>
          </Box>
        </Box>
      </Stack>
      <PromotionImage isOpen={isOpen} closeModal={() => setOpen(false)} />
    </Page>
  );
};

const PromotionImage = ({
  isOpen,
  closeModal,
}: {
  isOpen: boolean;
  closeModal: () => void;
}) => {
    const navigate = useNavigate();

    return (
    <BootstrapDialog open={isOpen} onClose={closeModal}>
        <DialogTitle sx={{py:0, display: 'flex', flexDirection: 'row-reverse'}}>
            <IconButton
                aria-label="close"
                onClick={closeModal}
                sx={{
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
        </DialogTitle>
      <DialogContent onClick={()=>navigate('/proizvodi')}>
          <Box
              component="img"
              sx={{
                  cursor: 'pointer',
                  display: "flex",
                  content: {
                      xs: `url(${promotionMobile})`,
                      md: `url(${promotion})`,
                      width: "100%",
                  },
                  objectFit: "contain",
              }}
              alt="Promocija 40%"
          />
      </DialogContent>
    </BootstrapDialog>
  );
};

export default HomePage;
