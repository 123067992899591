import {createContext, PropsWithChildren, useContext, useState} from "react";
import {DataI, useFirestoreCollection} from "../hooks/firestore/useFirestoreCollection";
import Product from "../model/Product";
import {orderBy, where} from "firebase/firestore";

const ProductsContext = createContext<DataI<Product> | null>(null);

type ProductsProviderType = {

}
const ProductsProvider = ({children}: PropsWithChildren<ProductsProviderType> ) => {
    const products = useFirestoreCollection<Product>("products", {
        queryConstraints: [where('visible', '==',true), orderBy("alias", "asc")],
    });
    return <ProductsContext.Provider value={products}>
        {children}
    </ProductsContext.Provider>
}

export const useProductsContext = () => {
    const currentContext = useContext(ProductsContext);

    if (!currentContext) {
        throw new Error(
            "useProductsContext has to be used within <ProductsContext.Provider>"
        );
    }

    return currentContext;
};

export default ProductsProvider;
