import React, {useState} from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  DialogContent,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import {useParams} from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CategoryCard from "../components/CategoryCard";
import garniture from "../assets/garniture.jpeg";
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {Autoplay, FreeMode, Navigation, Thumbs} from "swiper";

import "swiper/css";
import "swiper/css/free-mode";
import {Swiper as SwiperClass} from "swiper/types";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import {useFirestoreDocument} from "../hooks/firestore/useFirestoreDocument";
import {Helmet} from "react-helmet";
import {doc, setDoc, where} from "firebase/firestore";
import PhotoViewer, {BootstrapDialog} from "../components/PhotoViewer";
import Product, {ProductFeature, WithId} from "../model/Product";
import useModal from "../hooks/useModal";
import {useFirestoreCollection} from "../hooks/firestore/useFirestoreCollection";
import {sofa} from "../data/Product.data";
import CloseIcon from "@mui/icons-material/Close";
import {ContactForm} from "./ContactPage";
// import {where} from "firebase/firestore";
import {firestore, storage} from "../firebaseConfig";
import {getDownloadURL, ref, uploadBytes} from "firebase/storage";

// Install Autoplay module
SwiperCore.use([Autoplay]);

interface ProductPageProps {
  product: WithId<Product>;
}

const prepareFeatures = (
  productFeatures?: ProductFeature[]
): ProductFeature[] => {
  if (!productFeatures) {
    return sofa.description;
  } else {
    let tempSofaDesc = [];
    for (const item of sofa.description) {
      const feature = productFeatures.find((i) => i.label === item.label);
      if (!feature) {
        tempSofaDesc.push(item);
      } else {
        if (feature.text) {
          tempSofaDesc.push(feature);
        }
      }
    }
    return tempSofaDesc;
  }
};

function swapElements(arr:any[], x: number, y:number){
  [arr[x], arr[y]] = [arr[y], arr[x]];
  return arr;
}

async function uploadImageAsPromise(imageFile: File, itemId: string) {
  const storageRef = ref(storage, `products/${itemId}/pictogram/${imageFile.name}`);

  const i = await uploadBytes(storageRef, imageFile);
  return getDownloadURL(i.ref);
}
const ProductPage = ({ product }: ProductPageProps) => {
  const { isOpen, openModal, closeModal } = useModal();
  const similarProducts = useFirestoreCollection<Product>("products", {
    queryConstraints: [where("similarProducts", "array-contains", product.id)],
  });

  const onSelectFiles = async (fList: FileList, itemId: string) => {
    const files = Array.from(fList);
    const fileUrls = await Promise.all(files.map(file=> uploadImageAsPromise(file, itemId)));
    return setDoc(doc(firestore, `products/${itemId}`), {pictogram: fileUrls}, {merge: true});
  };

  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperClass | null>(null);
  return (
    <Box mt={8} py={8}>
      <Container maxWidth="xl">
        <Grid container spacing={6}>
          <Grid item lg={6} xs={12}>
            <Box>
              <Swiper
                  spaceBetween={10}
                  navigation={true}
                  autoHeight
                  thumbs={{swiper: thumbsSwiper}}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper2"
                  lazy
              >
                {(product.images || [garniture])?.map(
                    (_: string, index: number) => (
                        <SwiperSlide key={`${index}-svg`}>
                          <img
                              src={_}
                              style={{
                                objectFit: "cover",
                                width: "100%",
                                height: "100%",
                              }}
                          />
                        </SwiperSlide>
                    )
                )}
                {/*<Button onClick={()=>{*/}
                {/*  console.log(product.images);*/}
                {/*  return setDoc(doc(firestore, `products/${product.id}`), {images: swapElements(product.images, 5, 0)}, {merge: true})*/}
                {/*}}>Change</Button>*/}
              </Swiper>
              <Box sx={{display: {xs: "none", md: "block"}}}>
                <Swiper
                    onSwiper={(swiper) => setThumbsSwiper(swiper)}
                    slidesPerView={3}
                    freeMode={true}
                    spaceBetween={10}
                    watchSlidesProgress={true}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="mySwiper"
                >
                  {(product.images || [garniture])?.map(
                      (_: string, index: number) => (
                          <SwiperSlide key={`${index}-svg`}>
                            <img
                                src={_}
                                style={{
                                  objectFit: "cover",
                                  height: "150px",
                                  width: "100%",
                                }}
                            />
                          </SwiperSlide>
                      )
                  )}
                </Swiper>
              </Box>
            </Box>
          </Grid>
          <Grid item lg={6} xs={12}>
            <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginBottom: "60px",
                  marginTop: "60px",
                  borderTopRightRadius: {
                    lg: "200px",
                  },
                  borderTopLeftRadius: {
                    lg: "200px",
                  },
                  border: "1px solid #D4D4D4",
                  padding: {
                    xs: "10px 20px",
                    lg: "30px 180px",
                  },
                }}
            >
              <Typography variant="h1" marginBottom={3} fontSize={40}>
                {product.name}
              </Typography>
              <Typography marginBottom={8} fontSize={16}>
                {product.description}
              </Typography>
              <Typography>Zainteresovani ste za ovaj proizvod?</Typography>
              <ContactFormAndButton product={product}/>
            </Box>
            <Box>
              <Container maxWidth="xl">
                <Accordion
                    sx={{py: 1, backgroundColor: "transparent"}}
                    elevation={0}
                >
                  <AccordionSummary
                      expandIcon={<ExpandMoreIcon/>}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                  >
                    <Typography>Opis Proizvoda</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {/*<ThumbnailGallery gallery={sofa.materials} />*/}
                    <TableContainer>
                      <Table aria-label="simple table">
                        <TableBody>
                          {prepareFeatures(product.features).map((row) => (
                              <TableRow key={row.label}>
                                <TableCell
                                    sx={{border: 0, color: "text.secondary"}}
                                    align="left"
                                >
                                  {row.label}
                                </TableCell>
                                <TableCell sx={{border: 0}} align="left">
                                  {row.text}
                                </TableCell>
                              </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </AccordionDetails>
                </Accordion>
                {product.pictogram && (
                    <Accordion
                        sx={{py: 1, backgroundColor: "transparent"}}
                        elevation={0}
                    >
                      <AccordionSummary
                          expandIcon={<ExpandMoreIcon/>}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                      >
                        <Typography>Dimenzije</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <img
                            onClick={openModal}
                            style={{maxWidth: "100%", cursor: "pointer"}}
                            src={product.pictogram[0]}
                        />
                      </AccordionDetails>
                    </Accordion>
                )}
                {/*<Accordion*/}
                {/*  sx={{ py: 1, backgroundColor: "transparent" }}*/}
                {/*  elevation={0}*/}
                {/*>*/}
                {/*  <AccordionSummary*/}
                {/*    expandIcon={<ExpandMoreIcon />}*/}
                {/*    aria-controls="panel2a-content"*/}
                {/*    id="panel2a-header"*/}
                {/*  >*/}
                {/*    <Box>*/}
                {/*      <Typography gutterBottom>Nogice</Typography>*/}
                {/*      <Typography variant="body2" color="textSecondary">*/}
                {/*        Ovde mozete pogledati sve opcije boja nogica*/}
                {/*      </Typography>*/}
                {/*    </Box>*/}
                {/*  </AccordionSummary>*/}
                {/*  <AccordionDetails>*/}
                {/*    <ThumbnailGallery gallery={sofa.materials} />*/}
                {/*  </AccordionDetails>*/}
                {/*</Accordion>*/}
                {/*<Accordion*/}
                {/*  sx={{ py: 1, backgroundColor: "transparent" }}*/}
                {/*  elevation={0}*/}
                {/*>*/}
                {/*  <AccordionSummary*/}
                {/*    expandIcon={<ExpandMoreIcon />}*/}
                {/*    aria-controls="panel3a-content"*/}
                {/*    id="panel3a-header"*/}
                {/*  >*/}
                {/*    <Typography>Materijal</Typography>*/}
                {/*  </AccordionSummary>*/}
                {/*  <AccordionDetails>*/}
                {/*    <ThumbnailGallery gallery={sofa.materials} />*/}
                {/*  </AccordionDetails>*/}
                {/*</Accordion>*/}
              </Container>
            </Box>
          </Grid>
          <input name="file" type="file" multiple onChange={(e) => onSelectFiles(e.target.files!, product.id)}/>
          {similarProducts.data?.length > 0 && (
              <Grid item lg={6} xs={12}>
                <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "16px 0",
                      borderTop: "1px solid #737373",
                      marginTop: "120px",
                    }}
                >
                  <Container maxWidth={false} disableGutters>
                    <Typography variant="h3" textAlign="center" my={3}>
                      Slični proizvodi
                    </Typography>
                    <Swiper
                        slidesPerView={"auto"}
                        spaceBetween={60}
                        initialSlide={3}
                        autoplay={{delay: 2000}}
                    >
                      {similarProducts.data.map((c, index) => (
                          <SwiperSlide
                              style={{maxWidth: "min-content"}}
                              key={index}
                          >
                            <CategoryCard
                                title={c.name}
                                img={c.images[0]}
                                href={`/proizvodi/${c.id}`}
                                rounded={false}
                                fixedSize={true}
                            />
                          </SwiperSlide>
                      ))}
                    </Swiper>
                  </Container>
                </Box>
              </Grid>
          )}
        </Grid>
        {product.pictogram && (
            <PhotoViewer
                image={product.pictogram[0]}
                open={isOpen}
                handleClose={closeModal}
            />
        )}
      </Container>
    </Box>
  );
};

const ContactFormAndButton = ({ product }: ContactFormAndButtonProps) => {
  const { openModal, isOpen, closeModal } = useModal();
  return (
    <>
      <Button onClick={openModal}>Pošalji upit</Button>
      <BootstrapDialog open={isOpen} onClose={closeModal}>
        <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Box>
            <ContactForm product={product} onSubmit={closeModal} />
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

interface ContactFormAndButtonProps {
  product: WithId<Product>;
}

const ProductPageWrapper = () => {
  const { id } = useParams();
  const { data } = useFirestoreDocument<any>(`products`, id!);
  return data ? (
    <>
      <Helmet>
        <title>{data?.name}</title>
      </Helmet>
      <ProductPage product={data} />
    </>
  ) : null;
};

export default ProductPageWrapper;
