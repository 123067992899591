import React from 'react';
import {Link, LinkProps} from 'react-router-dom';

const ExternalLink = ({ to, children }: LinkProps & React.RefAttributes<HTMLAnchorElement>) => {
    const handleRedirect = () => {
        if(typeof to==='string'){
            window.open(to, '_blank');
        }
    };

    return (
        <Link to="#" onClick={handleRedirect}>
            {children}
        </Link>
    );
};

export default ExternalLink;
