import React, { useEffect, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import LinkIcon from "./icons/LinkIcon";
import UniversalLink from "./links/UniversalLink";

interface Props {
  href: string;
  title: string;
  img: string;
  rounded?: boolean;
  description?: string;
  fixedSize?: boolean;
}

const CategoryCard: React.FC<Props> = ({
  href,
  title,
  img,
  description,
  fixedSize,
  rounded = true,
}) => {
  return (
    <UniversalLink to={href}>
      <Stack
        maxWidth="100%"
        spacing={2}
        justifyContent="center"
        sx={{
          img: {
            transition: "transform ease-in-out .15s",
          },
          "&:hover": {
            img: {
              transform: "scale(1.05)",
            },
          },
        }}
      >
        <Box
          width={fixedSize ? 300 : undefined}
          height={fixedSize ? 250 : 350}
          overflow="hidden"
          style={{
            ...(rounded && {
              borderTopLeftRadius: "50%",
              borderTopRightRadius: "50%",
            }),
          }}
        >
          <img
            style={{
              objectFit: "cover",
            }}
            src={img}
            height="100%"
            width="100%"
            alt={title}
          />
        </Box>
        {description && (
          <Typography textAlign="left" component="h3" variant="h5">
            {title}
          </Typography>
        )}
        <Box display="flex" sx={{ p: 3 }}>
          <Box
            borderRight="0.6px solid black"
            borderTop={"0.6px solid black"}
            py={1}
            px={3}
            flex={1}
          >
            {description || title}
          </Box>
          <Box
            borderTop={"0.6px solid black"}
            display="flex"
            justifyContent="center"
            alignItems="center"
            p={2}
          >
            <LinkIcon size="small" />
          </Box>
        </Box>
      </Stack>
    </UniversalLink>
  );
};

export default CategoryCard;
