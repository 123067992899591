import * as React from "react";
import {useMemo, useState} from "react";
import {styled, Theme} from "@mui/material/styles";
import {Box, Container, Divider, Grid, Paper, Skeleton, Stack, Typography, useMediaQuery,} from "@mui/material";
import CategoryCard from "../components/CategoryCard";
import garniture from "../assets/garniture.jpeg";
import {Helmet} from "react-helmet";
import Product, {WithId} from "../model/Product";
import {groupBy} from "lodash/fp";
import {sofaTypes} from "./HomePage";
import {useProductsContext} from "../providers/ProductsProvider";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const categories = {
  xFmlh0gjwJiqWz1Lckwi: "Sofe bez mehanizma",
  Ea70yRrcWrvaMAiT3mNR: "Sofe sa mehanizmom",
  GFo2UCg5hBBS7N7IHfE2: "Fotelje",
};

const ProductsPage = () => {
  const products = useProductsContext();
  const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const [itemRefs, setItemRefs] = useState(() =>
      sofaTypes.map(() => React.createRef<HTMLDivElement>())
  );
  const grouped = useMemo(
    () => groupBy<WithId<Product>>((value) => value.category)(products.data),
    [products]
  );

  const focus = (index: number) => {
    const yOffset = isMd ? -86 : -188;
    if(!itemRefs[index].current) return;
    const y = (itemRefs[index].current?.getBoundingClientRect().top ?? 0) + window.pageYOffset + yOffset;

    window.scrollTo({top: y, behavior: 'smooth'});
    return;
  };

  return (
    <div>
      <Box mt={8} py={8}>
        <Container maxWidth="xl">
          <Grid container spacing={{ xl: 10, sm: 4, xs: 2 }} justifyContent="center">
            {sofaTypes.map(({ title, image, href }, index) => (
              <Grid item xs={6} sm={4} onClick={()=> focus(index)} key={title}>
                <Stack
                  maxWidth="100%"
                  spacing={1}
                  justifyContent="center"
                  display="flex"
                  alignItems="center"
                  sx={{
                    p: 1,
                    cursor: "pointer",
                    img: {
                      transition: "transform ease-in-out .15s",
                    },
                    "&:hover": {
                      img: {
                        transform: "scale(1.05)",
                      },
                    },
                  }}
                >
                  <Box
                    // width={fixedSize ? 300 : undefined}
                    // height={150}
                    overflow="hidden"
                    style={{
                      borderTopLeftRadius: "50%",
                      borderTopRightRadius: "50%",
                    }}
                    sx={{
                      height: {
                        sm: 150,
                        lg: 200
                      }
                    }}
                  >
                    <img
                      style={{
                        objectFit: "cover",
                      }}
                      src={image}
                      height="100%"
                      width="100%"
                      alt={title}
                    />
                  </Box>
                  {title && (
                    <Typography
                      textAlign="center"
                      component="h5"
                      variant="subtitle1"
                    >
                      {title}
                    </Typography>
                  )}
                </Stack>
              </Grid>
            ))}
          </Grid>
          <Divider sx={{ my: 5, backgroundColor: "#A3A3A3", display: { sm: 'flex', xs: 'none' } }} />
          {!products?.loading ? (
            Object.entries(grouped).map(([category, prods], index) => (
              <Stack ref={itemRefs[index]} key={category}>
                <Divider sx={{ my: 5 }}>
                  {/*  @ts-ignore*/}
                  {categories[category]}
                </Divider>
                <Grid container spacing={2}>
                  {prods.map((c, index) => (
                    <Grid item xs={12} key={c.id} sm={6} lg={4}>
                      <CategoryCard
                        title={c.name}
                        img={c.images?.[0] || garniture}
                        href={`/proizvodi/${c.id}`}
                        rounded={false}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Stack>
            ))
          ) : (
            <Grid container spacing={2}>
              {Array.from({ length: 21 }).map((i, index) => (
                <Grid item xs={12} key={index} sm={6} lg={4}>
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    height="300px"
                    sx={{ maxWidth: "100%" }}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </Container>
      </Box>
    </div>
  );
};

const ProductsPageWrapper = () => {
  return (
    <>
      <Helmet>
        <title>Italiana Salotti - Proizvodi</title>
      </Helmet>
      <ProductsPage />
    </>
  );
};

export default ProductsPageWrapper;
