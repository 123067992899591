import { Dialog, DialogContent, IconButton } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";

interface PhotoViewerProps {
  image: string;
  open: boolean;
  handleClose: VoidFunction;
}
const PhotoViewer = ({ open, handleClose, image }: PhotoViewerProps) => {
  return (
    <BootstrapDialog open={open} onClose={handleClose}>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <img style={{ maxWidth: "100%" }} src={image} />
      </DialogContent>
    </BootstrapDialog>
  );
};

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default PhotoViewer;
