import {PropsWithChildren} from "react";
import {Helmet} from "react-helmet";
import {Container} from "@mui/material";

const Page = ({title, children}: PropsWithChildren<PageProps>) => {

    return <>
        <Helmet>
            <title>{title}</title>
        </Helmet>
        <Container maxWidth="xl">
            {children}
        </Container>
    </>
};

interface PageProps {
    title: string
}

export default Page;
