import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, createRoutesFromElements, Route, RouterProvider} from "react-router-dom";
import HomePage from "./pages/HomePage";
import ProductsPage from "./pages/ProductsPage";
import ProductPage from "./pages/ProductPage";
import ContactPage from "./pages/ContactPage";
import AboutPage from "./pages/AboutPage";
import ProductDashboardPage from "./pages/admin/ProductDashboardPage";


const router = createBrowserRouter(createRoutesFromElements(<Route path="/" element={<App/>}>
    <Route path="/" element={<HomePage />} index/>
    <Route path="/proizvodi/:id" element={<ProductPage />} />
    <Route path="/proizvodi" element={<ProductsPage />} />
    <Route path="/kontakt" element={<ContactPage />} />
    <Route path="/o-nama" element={<AboutPage />} />
    <Route path="/admin" element={<ProductDashboardPage />} />
</Route>))

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <RouterProvider router={router} />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
