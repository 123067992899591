import jela from "../assets/materials/jela.jpeg";
import pine from "../assets/materials/pine.jpeg";
import walnut from "../assets/materials/walnut.jpeg";
import oak from "../assets/materials/oak.jpeg";

export const sofa = {
  name: "Garnitura",
  description: [
    {
      label: "Opis",
      text: "Modularna garnitura sa opcijom za kombinovanje",
    },
    {
      label: "Osnova/Struktura",
      text: "Drvo i proizvodi od drveta",
    },
    {
      label: "Nasloni i Jastuci sedenja",
      text: "Poliuretanska pena razlicite gustine",
    },
    {
      label: "Ispod presvlake",
      text: "Pamucno platno",
    },
    {
      label: "Zavrsna presvlaka",
      text: "Koza ili tkanina",
    },
    {
      label: "Nogice",
      text: "Liveni aluminijum",
    },
    {
      label: "Profili",
      text: "Obojeni aluminijum",
    },
  ],
  materials: [
    {
      label: "Jela",
      image: jela,
    },
    {
      label: "Bor",
      image: pine,
    },
    {
      label: "Orah",
      image: walnut,
    },
    {
      label: "Hrast",
      image: oak,
    },
  ],
};
