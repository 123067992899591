import {Link as LN, LinkProps, To} from "react-router-dom";
import React from "react";
import ExternalLink from "./ExternalLink";
import {Link} from "@mui/material";

const UniversalLink = ({to, children}: LinkProps & React.RefAttributes<HTMLAnchorElement>) => {
    return isExternalLink(to) ? <ExternalLink to={to}>{children}</ExternalLink> : <Link
        to={to} component={LN}>{children}</Link>
}

function isExternalLink(to: To) {
    return (typeof to === 'string') && to.startsWith('http');
}

export default UniversalLink;
