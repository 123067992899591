import { Alert, Box, Grid, Stack, Typography } from "@mui/material";
import RHFTextInput from "../components/admin/inputs/RHFTextInput";
import { FormProvider, useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import { ContactDetails, LocationDetails } from "../components/Footer";
import React, { useEffect, useState } from "react";
import Page from "./Page";
import { addDoc, collection } from "firebase/firestore";
import { firestore } from "../firebaseConfig";
import { LoadingButton } from "@mui/lab";
import Product, { WithId } from "../model/Product";
import { htmlTemplate, modelTemplate } from "../data/Email.data";

type ContactFormProps = {
  email: string;
  message: string;
};

const ContactPage = () => {
  return (
    <Page title="Kontakt">
      <Typography variant={"h2"} sx={{ fontWeight: "normal", my: 8 }}>
        Kontakt
      </Typography>
      <Grid container spacing={12}>
        <Grid item xs={12} lg={6}>
          <ContactForm />
        </Grid>

        <Grid item xs={12} lg={6}>
          <Box>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11323.276910081513!2d20.3999447!3d44.8048726!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475a656ffe543703%3A0x31811fca17af6f82!2sItaliana%20Salotti%20-%20salon%20name%C5%A1taja!5e0!3m2!1sen!2srs!4v1694105510055!5m2!1sen!2srs"
              style={{
                border: 0,
                width: "100%",
                height: "100%",
                minHeight: "450px",
              }}
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
            <Stack
              justifyContent="space-between"
              sx={{
                flexDirection: { xs: "column", md: "row" },
                alignItems: { xs: "start" },
              }}
            >
              <ContactDetails />
              <LocationDetails />
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Page>
  );
};

export const ContactForm = ({
  product,
  onSubmit,
}: {
  product?: WithId<Product>;
  onSubmit?: VoidFunction;
}) => {
  const methods = useForm<ContactFormProps>({
    defaultValues: {
      email: "",
      message: "",
    },
  });

  const [loading, setLoading] = useState(false);
  const [showInfo, setShowInfo] = useState(false);

  const onContactFormSubmit = async (e: ContactFormProps) => {
    console.log('Send message');
    setLoading(true);
    const model = product?.name
      ? modelTemplate.replace("{{modelLink}}", product.name)
      : "";
    const addingDoc = addDoc(collection(firestore, "emails"), {
      to: [e.email],
      bcc: ["italianasalotti.bg@gmail.com"],
      message: {
        subject: "✉️ Poruka Primljena, Vaš Italiana Salotti",
        // text: `${modelName}Poruka: ${e.message} \nOdgovoriti na: ${e.email}`,
        html: htmlTemplate
          .replace("{{model}}", model)
          .replace("{{message}}", e.message)
          .replace("{{email}}", e.email),
      },
    });
    try {
      await Promise.all([addingDoc, fakeLoader(1000)]);
      setLoading(false);
      methods.reset();
      setShowInfo(true);
      onSubmit?.();
    } catch (e) {
      console.log("Error while sending email", e);
    }
  };
  useEffect(() => {
    if (methods.formState.isDirty) {
      setShowInfo(false);
    }
  }, [methods.formState.isDirty]);

  return (
    <FormProvider {...methods}>
      <Box>
        <Typography sx={{ mb: 10 }} fontWeight={"normal"}>
          Hajde da vaš projekat postane stvarnost Bilo da ste arhitekta,
          dizajner, izvođač radova ili pojedinac, pošaljite nam svu
          dokumentaciju koja se odnosi na vaš projekat i mi ćemo pregledati vaš
          dosije kako bismo vam poslali fer i detaljnu ponudu.
        </Typography>
        <form
          id="contact-form"
          onSubmit={methods.handleSubmit(onContactFormSubmit)}
        >
          <Box display={"flex"} flexDirection={"column"} gap={2}>
            <RHFTextInput
              name={"email"}
              label={"E-mail"}
              variant={"standard"}
            />
            <RHFTextInput
              name={"message"}
              label={"Poruka"}
              variant={"standard"}
              multiline
              rows={3}
            />
            <LoadingButton type={"submit"} loading={loading} form="contact-form">
              Posalji
            </LoadingButton>
          </Box>
        </form>
        {showInfo && (
          <Alert severity="info">
            Poruka je poslata! Kontaktiracemo vas u najkracem roku, hvala.
          </Alert>
        )}
      </Box>
    </FormProvider>
  );
};

function fakeLoader(ms: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}
const html = ``;
export default ContactPage;
