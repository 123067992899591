import {Controller, useFormContext} from "react-hook-form";
import {TextField} from "@mui/material";
import { get } from "lodash/fp";
import {TextFieldProps} from "@mui/material/TextField/TextField";

type RHFTextInputProps = {
    name: string;
    label?: string;
} & TextFieldProps;


const RHFTextInput = ({name, label, ...other}: RHFTextInputProps) => {
    const {control, formState: {errors}} = useFormContext();
    return <Controller
        rules={{
            required: {value: true, message: "Ovo polje je obavezno."},
        }}
        name={name}
        control={control}
        render={({field}) => (
            <TextField
                {...field}
                {...other}
                label={label}
                error={!!get(name)(errors)}
                helperText={get(`${name}.message`)(errors)}
            />
        )}
    />

};

export default RHFTextInput;
