import React from "react";
import {Outlet, ScrollRestoration} from "react-router-dom";
import {ThemeProvider} from "@mui/material/styles";
import Footer from "./components/Footer";
import {themeLight} from "./theme";
import {Box, CssBaseline} from "@mui/material";
import Navbar from "./components/Navbar";

import ProductsProvider from "./providers/ProductsProvider";

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={themeLight}>
        <CssBaseline />
          {/*<ScrollToTop />*/}
          <ScrollRestoration />
          <Navbar />
          <ProductsProvider>

          <Box sx={{ padding: { xs: "86px 0", md: "188px 0" } }}>
              <Outlet />
          </Box>
          </ProductsProvider>

          <Footer />
      </ThemeProvider>
    </div>
  );
}

export default App;
